// AppInsights.js
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import {SeverityLevel} from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { useEffect } from 'react';
import fetch from 'isomorphic-unfetch';
import { dateNow } from '@microsoft/applicationinsights-core-js';

//console.log(process.env)

//const reactPlugin = new ReactPlugin();
const config = {
  connectionString: `${process.env.NEXT_PUBLIC_APPLICATIONINSIGHTS_CONNECTION_STRING}`,
  //extensions: [reactPlugin],
}
const appInsights = new ApplicationInsights({
    config: config
});
appInsights.loadAppInsights();

const logClientException = (error) => {
  appInsights.trackException({ error: error, severityLevel: SeverityLevel.Error });
}

const logClientMessage = (message) => {
  appInsights.trackTrace({ message: message });
}

const useAppInsightsTrackPageView = () => {
    useEffect(() => {
      appInsights.trackPageView({name: location.pathname})
      console.log('trackPageView', location.pathname)
    }, [location?.pathname]);
}

// This feels a bit hacky to do this manually but seems to be the only way to log exceptions from 
// getCustomInitialProps as NextJS won't allow server libs to be loaded there (seems to think its browser code)
const logServerException = async (error) => {
  try {
    const configPieces = config.connectionString.split(';');
    const iKey = configPieces[0].split('=')[1];
    let endpoint = configPieces[1].split('=')[1];

    let body = [
      {
        name: `Microsoft.ApplicationInsights.${iKey}.Exception`,
        time: (new Date()).toISOString(),
        iKey: iKey,
        tags: {},
        data: {
          baseType: 'ExceptionData',
          baseData: {
            ver: 2,
            exceptions: [
              {
                message: error.message,
                typeName: 'Error',
                stack: error.stack,
                hasFullStack: false,
                parsedStack: undefined
              }
            ],
            severityLevel: 3,
            properties: {
              typeName: "Error"
            }        
          }
        }
      }
    ]

    const res = await fetch(endpoint + '/v2/track', {method: 'POST', body: JSON.stringify(body)});
    if (!res.ok) {
      throw new Error(`logServerException failed: ${res.status}`);
    }
  } catch (error) {
    console.error(error);
  }
  
}

export { useAppInsightsTrackPageView, logClientException, logClientMessage, logServerException};

